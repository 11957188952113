body {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  -webkit-user-drag: none;
}

img {
  -webkit-user-drag: none;
}
